export const APCDelconMixin = {

	methods: {

		checkPermission(){
			// return this.checkOrderTypePermission({
			// 	types: {
			//
			// 	},
			// 	currentType: this.$route.params.type
			// })
			return true
		},

		saveAPCDelcon(edit = false) {

			if (!this.APCD.firstValidationAdmin()) return

			let data = this.APCD.prepareSave()
			let saveType = 'createAPCDelcon'

			if (edit) {
				data = {
					id: this.APCD.getId(),
					data: data
				}
				saveType = 'updateAPCDelcon'
			}

			console.log(data);
			console.log(saveType);

			return this.$store.dispatch(saveType, data).then(response => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
				console.log(data);

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						if (edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
						} else {
							this.openNotify('success', 'common_notificationRecordCreated')
						}

						this.$router.push(this.$store.getters.GET_PATHS.reportsAPCDelcon)
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})

		},
	}
}
