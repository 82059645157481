<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['apcDelcon_scanNumber'])"></div>
        <SearchInput
            class="scan-ico"
            :label="$t('apcDelcon_scanNumber.localization_value.value')"
            v-model="scanValue"
            @submit="scan($event)"
            @keyup.native="scan($event, true)"
        />
        <div class="color-red pt-2"
             v-if="scanErrorItem !== ''"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['apcDelcon_notFound'])"></div>
          {{"'" + scanErrorItem + "'"}} {{$t('apcDelcon_notFound.localization_value.value')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import SearchInput from "../../../../UI/inputs/SearchInput/SearchInput";

export default {
  name: "ScannerBlock",
  components: {
    SearchInput,
    // DefaultInput
  },

  props: {
    ordersFBM: Array,
    APCD: Object,
  },

  data() {
    return {
      scanValue: '',
      scanErrorItem: '',
    }
  },

  methods: {
    scan(e, checkPressedKey = false) {

      if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

      this.scanErrorItem = ''
      let requestType = 'fetchSearchOrderUnion'
      let queryString = `?scan_number=${this.scanValue}`

      this.$store.dispatch(requestType, queryString).then((response) => {

        let respData = this.getRespData(response)


        if (respData.length) {
          let foundItem = respData[0]

          let itemInList = this.ordersFBM.find(item => {
            return item.id === foundItem?.object?.id
          })

          if (itemInList && !this.APCD.data.relatedOrders.includes(itemInList)) {
            this.APCD.data.relatedOrders = [...this.APCD.data.relatedOrders, itemInList]
          }
        }
        else {
          let itemWithTrackInList = this.ordersFBM.find(item => {
            return item.tracking_number === this.scanValue
          })

          if (!itemWithTrackInList) {
            this.scanErrorItem = this.scanValue
          }

          if (itemWithTrackInList && !this.APCD.data.relatedOrders.includes(itemWithTrackInList)) {
            this.APCD.data.relatedOrders = [...this.APCD.data.relatedOrders, itemWithTrackInList]
          }
        }

        this.scanValue = ''

      }).catch(error => this.createErrorLog(error))
    }
  },
}
</script>

<style scoped>

</style>