import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function APCDelcon() {

    this.checkValid = checkValid

    this.data = {
        id: '',
        relatedOrders: [],
        shippingCompany: '',
        createdAt: null,
        trackingNumber: '',
        comment: '',

        status: '',
        sendAt: null,

    }

    this.validation = {
        id: false,
        relatedOrders: false,
        shippingCompany: false,
        trackingNumber: false,
        comment: false,
        status: false,
        sendAt: false,
    }

    this.validationTranslate = {
        id: '',
        relatedOrders: '',
        shippingCompany: '',
        trackingNumber: '',
        comment: '',
        status: '',
        sendAt: '',
    }

    this.validationTxt = {
        id: false,
        relatedOrders: false,
        shippingCompany: false,
        trackingNumber: false,
        comment: false,
        status: false,
        sendAt: false,
    }

    /**
     * Getters
     */
    this.getId = () => {
        return this.data.id
    }

    this.getWarehouse = () => {
        return this.data.warehouse
    }
    this.getShippingCompany = () => {
        return this.data.shippingCompany
    }
    this.getCreatedAt = () => {
        return this.data.createdAt
    }
    this.getSendAt = () => {
        return this.data.sendAt
    }
    this.getTrackingNumber = () => {
        return this.data.trackingNumber
    }
    this.getComment = () => {
        return this.data.comment
    }
    this.getStatus = () => {
        return this.data.status
    }
    this.getRelatedOrders = () => {
        return this.data.relatedOrders
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setWarehouse = (val) => {
        this.data.warehouse = val
    }
    this.setShippingCompany = (val) => {
        this.data.shippingCompany = val
    }
    this.setCreatedAt = (val) => {
        this.data.createdAt = val
    }
    this.setSendAt = (val) => {
        this.data.sendAt = val
    }
    this.setTrackingNumber = (val) => {
        this.data.trackingNumber = val
    }
    this.setComment = (val) => {
        this.data.comment = val
    }
    this.setStatus = (val) => {
        this.data.status = val
    }

    this.setRelatedOrders = (val) => {

        console.log(33333333333333, val, this.data.relatedOrders.length);
        if(this.data.relatedOrders.length >= val.length){
            this.data.relatedOrders = val
            return
        }

        this.data.relatedOrders = val
    }
}

/**
 * Global Setters
 */
APCDelcon.prototype.setAPCDelconItem = function (item, shippingCompanies = false, orders = false,  that) {
    this.setId(item?.id)
    this.setTrackingNumber(item?.tracking_number)
    this.setComment(item?.comment)
    this.setCreatedAt(item?.created_at ? that.$moment(item?.created_at).format('YYYY-MM-DD') : null)

    if (shippingCompanies) {
        this.setShippingCompany(shippingCompanies.find(scItem => {
            return scItem.id === item.delivery_service.id
        }))
    }

    this.setStatus(item.status)
    if (this.getStatus()) {
        this.setSendAt(item?.send_at ? that.$moment(item?.send_at).format('YYYY-MM-DD HH:mm') : null)
    }

    if (orders) {
        let ordersToSet = item?.orders_fbm.map(orderItem => {
            return orders.find(findItem => {
                return findItem.id === orderItem.id
            })
        })

        this.setRelatedOrders(ordersToSet)
    }

}

/**
 * Functions
 */


/**
 * Prepare Data
 */

APCDelcon.prototype.prepareSave = function () {


    let preparedData = {
        'delivery_service_id': this.getShippingCompany()?.id,
        'order_fbm_ids': this.getRelatedOrders().length ? this.getRelatedOrders().map(item => {return item.id}) : [],
        'tracking_number': this.getTrackingNumber(),
        'comment': this.getComment(),
    }

    if (this.getStatus()) {
        preparedData['send_at'] = this.getSendAt()
    }

    console.log(preparedData);

    return preparedData
}

/**
 * Validations
 */

APCDelcon.prototype.firstValidationAdmin = function () {

    let validationItems = {
        shippingCompany: this.getShippingCompany(),
    }

    let validationOptions = {
        shippingCompany: {type: ['empty']},
    }

    return (this.checkValid(validationItems, validationOptions))
}
